// HOME TABLET

//Landing
.landing
  margin-top: 4rem
  margin-bottom: 1rem
  padding: 0 0 5rem 0
  background: $bg-degraded
  border-radius: 0 0 70px 70px
  text-align: center
  &__animation
    div
      width: 100vw
  &__text
    text-align: center
    padding: 0 3rem
    h1
      margin-top: 0
      color: $color-yellow
      font-size: $main-title-tb
    p
      text-align: center
      font-size: $text-tb
      color: $color-gray-text
      padding-bottom: 3rem


// Benefits

.benefits
  &__container
    padding: 3rem
  &__title-h2
    font-weight: 300
    text-align: center
    font-size: $main-title-mb
    color: $color-gray-text
    margin: 5rem auto
  &__card-left
    margin: 5rem auto
    text-align: center
    position: relative
    &--icon
      z-index: 1
      margin-bottom: -2rem
      img
        width: 3.5rem
        box-shadow: 0 0 46px #00000029
        border-radius: 15px
        padding: .25rem
        background-color: $color-white
    &--text
      text-align: center
      box-shadow: 0 0 46px #00000029
      padding: 1rem 2rem
      border-radius: 30px
      h2
        font-size: $nd-title-tb
        color: $color-blue
      p
        color: $color-gray-text
        font-size: $text-tb
  &__card-right
    margin: 5rem auto
    text-align: center
    position: relative
    &--icon
      z-index: 1
      margin-bottom: -2rem
      img
        background-color: $color-white
        width: 3.5rem
        box-shadow: 0 0 46px #00000029
        border-radius: 15px
        padding: .25rem
    &--text
      text-align: center
      box-shadow: 0 0 46px #00000029
      padding: 1rem 2rem
      border-radius: 30px
      h2
        font-size: $nd-title-tb
        color: $color-blue
      p
        color: $color-gray-text
        font-size: $text-tb
  &__talki
    margin-top: 8rem
    text-align: center
    h2
      font-size: $main-title-tb
      color: $color-yellow
    p
      font-size: $text-tb
      color: $color-gray-text
      padding-bottom: 2rem
    &-button
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      img
        margin-top: 2rem
        width: 7rem

// Freetrial

.freetrial
  margin-top: -4.5rem
  background: $bg-degraded-nd
  border-radius: 70px 70px 0 0
  &__container
    text-align: center
  &__text
    padding: $container-tablet
    h2
      font-size: $main-title-tb
      color: $color-yellow
      padding-top: 5rem
    p
      font-size: $text-tb
      color: $color-gray-text
      padding-bottom: 3rem
  &__mockup
    margin: 3rem auto
    img
      width: 45rem


// Plans

.plans
  &__container
    margin-top: 10rem
    padding: 3rem
    @media (min-width: 1024px)
      display: grid
      grid-column-gap: 2rem
      grid-template-columns: 1fr 1fr
      grid-template-rows: 1fr 15rem 1fr 5rem
  &__basic
    box-shadow: 0 0 46px #00000029
    margin-top: 5rem
    padding-top: 1rem
    padding-bottom: 5rem
    background-color: $color-gray-box
    border-radius: 30px
    @media (min-width: 1024px)
      grid-column: 1/2
      grid-row: 1/2
      margin-top: 0
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-blue
        font-size: $nd-title-tb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-blue
        border: none
      p
        font-size: $nd-title-tb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 5rem
      display: grid
      grid-template-columns: 2rem 14rem
      justify-content: center
      align-items: center
      img
        margin: 0 auto
        width: 2rem
      p
        padding-left: 1rem
        font-size: $text-tb
  &__advance
    padding-top: 1rem
    box-shadow: 0 0 46px #00000029
    margin-top: 5rem
    padding-bottom: 3rem
    background-color: $color-gray-box
    border-radius: 30px
    @media (min-width: 1024px)
      grid-column: 2/3
      grid-row: 1/2
      margin-top: 0
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-green-avanzado
        font-size: $nd-title-tb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-green-avanzado
        border: none
      p
        font-size: $nd-title-tb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 5rem
      display: grid
      grid-template-columns: 2rem 14rem
      align-items: center
      justify-content: center
      img
        width: 2rem
      p
        padding-left: 1rem
        font-size: $text-tb
  &__label
    margin-top: 10rem
    position: relative
    border-radius: 30px 30px 0 0
    @media (min-width: 1024px)
      grid-column: 1/2
      grid-row: 2/3
      margin-top: 10rem
    &-talki
      img
        position: absolute
        left: 40%
        top: -5.65rem
        width: 10rem
    &-text
      background-color: $color-pink-premium
      border-radius: 30px 30px 0 0
      text-align: center
      color: $color-white
      font-size: $nd-title-tb
      padding: .5rem 0
  &__premium
    box-shadow: 0 0 46px #00000029
    margin-top: 0
    padding-bottom: 3rem
    padding-top: 1rem
    width: 100%
    background-color: $color-gray-box
    border-radius:  0 0 30px 30px
    @media (min-width: 1024px)
      grid-column: 1/2
      grid-row: 3/5
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-pink-premium
        font-size: $nd-title-tb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-pink-premium
        border: none
      p
        font-size: $nd-title-tb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 5rem
      display: grid
      grid-template-columns: 2rem 14rem
      justify-content: center
      align-items: center
      font-size: $text-mb
      img
        width: 2rem
      p
        padding-left: 1rem
        font-size: $text-tb
  &__enterprise
    box-shadow: 0 0 46px #00000029
    margin-top: 5rem
    padding-bottom: 3rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 30px
    @media (min-width: 1024px)
      grid-column: 2/3
      grid-row: 3/4
      margin-top: 0
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-yellow-enterprise
        font-size: $nd-title-tb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-yellow-enterprise
        border: none
      p
        font-size: $nd-title-tb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 5rem
      display: grid
      grid-template-columns: 2rem 14rem
      justify-content: center
      align-items: center
      img
        width: 2rem
      p
        padding-left: 1rem
        font-size: $text-tb

.disabled
  color: $color-gray-nd
.bold
  font-weight: 600

//LOGOS

.logos
  &__container
    margin: 10rem 3rem
  &__box
    padding: 3rem
    background-color: $color-gray-box
    border-radius: 30px
    text-align: center
    &-text
      ul
        padding: 0
        text-align: left
        p
          margin: 0
          font-size: $text-tb
      p
        margin-bottom: 5rem
        font-size: $text-tb
    &-img
      margin-top: 2rem
      img
        width: 10rem
        height: 100%
        margin: 1rem