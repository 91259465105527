// HEADER TABLET

.header
  position: fixed
  top: 0
  right: 0
  left: 0
  z-index: 1000
  font-family: Poppins
  background-color: $color-white
  &__container
    display: flex
    align-items: center
    justify-content: space-between
    padding: $container-menu-mb
    box-shadow: 0 3px 6px $color-gray-shadow
  &__logo
    img
      width: $logo-menu-mb
  &__menu
    img
      width: 2rem
    ul
      padding-top: 3rem
      height: 80vh
      position: fixed
      bottom: 0
      left: 0
      right: 0
      background-color: $color-gray-menu
      justify-content: space-between
      border-radius: 60px 60px 0 0
      margin: 0
      @media (min-width: 767px) and (max-height: 567px)
        height: 67vh
      li
        margin: 0 2rem
        a
          color: $color-gray
          p
            border-bottom: .2rem solid transparent
            @media (min-width: 767px)
              margin: .75rem 0
      hr
        margin: 3rem 2rem
        width:  5%
        height: .5px
        background-color: $color-gray-text
        border: none
        @media (min-width: 768px)
          margin: 1rem 2rem
  &__buttons
    display: none


.menu-open
  transform: translateY(0)
  transition: 1s

.menu-close
  transform: translateY(100vh)
  transition: 1s