//FOOTER TABLET

.footer
  margin-top: 10rem
  background-color: $color-gray
  color: $color-gray-light
  border-radius: 0 100px 0 0
  &__container
    display: flex
    padding: $container-tablet
    justify-content: space-between
    align-items: center
  &__links
    ul
      padding: 0
      li
        margin: 0
        a
          color: $color-gray-light
  &__logo
    text-align: right
    p
      margin-bottom: 0
    &-img
      div
        margin: 0
      a
        img
          width: 10rem

